import React, { FC } from 'react';
import * as Styled from './mobile-layout.styled';
import {
  ActionButton,
  ActionButtonSize,
  ActionButtonVariant,
} from '@nn-virtual-pen/ui';
import { MobileLayoutProps } from './mobile-layout.types';
import { TranslationKey, useTranslate } from '@nn-virtual-pen/education/data-access';

export const MobileLayout: FC<MobileLayoutProps> = ({
  header,
  buttonText,
  buttonTitle,
  buttonOnClick,
  children,
}) => {
  const { t } = useTranslate();
  return (
    <Styled.Wrapper>
      <Styled.Header>{header}</Styled.Header>
      <Styled.Content>{children}</Styled.Content>
      <Styled.Footer>
        <ActionButton
          type="button"
          onClick={buttonOnClick}
          variant={ActionButtonVariant.primary}
          size={ActionButtonSize.wide}
          title={buttonTitle}
        >
          {buttonText}
        </ActionButton>
        <Styled.TermsText>
          {t(TranslationKey.page1_terms_desktop_1)}{' '}
          <Styled.TermsLink to={'/terms-of-condition'}>
            {t(TranslationKey.terms_of_condition)}
          </Styled.TermsLink>
        </Styled.TermsText>
      </Styled.Footer>
    </Styled.Wrapper>
  );
};
