import React from 'react';
import * as Styled from './initialization.styled';
import { Spinner } from "@nn-virtual-pen/education/ui";

export const Initialization = () => {
  return (
       <Styled.Wrapper>
         <div>
           <Spinner />
         </div>
       </Styled.Wrapper>
  );
};
