import React, { FC } from 'react';
import { MobileLayout, Title } from '../mobile-layout';
import { MobileHomeProps } from './mobile-home.types';
import { Content } from '../content';
import { useTranslate, TranslationKey } from '@nn-virtual-pen/education/data-access';

export const MobileHome: FC<MobileHomeProps> = ({ callback }) => {
  const { t } = useTranslate();

  return (
    <MobileLayout
      buttonText={t(TranslationKey.continue_button)}
      buttonTitle={t(TranslationKey.continue_button)}
      buttonOnClick={callback}
      header={<Title>{t(TranslationKey.pen_text)}</Title>}
    >
      <Content />
    </MobileLayout>
  );
};
