import React from 'react';
import { ModelViewer } from '@nn-virtual-pen/education/utils';
import {
  Product,
  Device,
  useUserContext,
} from '@nn-virtual-pen/education/data-access';
import { NoPenPlaceholder } from '../no-pen-placeholder';

export const Content = () => {
  const { configuration } = useUserContext();
  const { device, product } = configuration;

  if (!product || !device) {
    return <NoPenPlaceholder />;
  }

  return (
    <ModelViewer
      allowRotation
      product={product as Product}
      device={device as Device}
    />
  );
};
