import React from 'react';
import { getIsBrowser } from '@nn-virtual-pen/utils';
import { useAuthContext, useUserContext } from '@nn-virtual-pen/education/data-access';
import { navigate } from 'gatsby';
import { RoutePath, useIsInitializing } from '@nn-virtual-pen/education/utils';
import { useLocation } from '@reach/router';

export const withRequireConfig = (WrappedComponent) => (props) => {
  const isBrowser = getIsBrowser();
  const { pathname } = useLocation();
  const isInitializing = useIsInitializing();

  const {
    actions,
    state: { token },
  } = useAuthContext();
  const user = useUserContext();

  const {
    configuration: { country, product, device },
    userInteracted,
  } = user;

  const configurationCompleted = country && product && device;

  if (isBrowser && !isInitializing && !token) {
    void navigate(RoutePath.Welcome);
    return null;
  }

  if (isBrowser && !isInitializing && token && !configurationCompleted) {
    void navigate(RoutePath.Configure);
    return null;
  }

  if (isBrowser && !userInteracted && pathname === RoutePath.Learning) {
    void navigate(RoutePath.Home);
    return null;
  }

  return (
    <WrappedComponent
      {...props}
      isLoading={isInitializing}
      actions={actions}
      user={user}
    />
  );
};
