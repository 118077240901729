import styled from 'styled-components';
import { path } from 'ramda';
import { Link } from '@reach/router';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.theme.palette.introBg};
  min-height: 100%;
  padding: 14px 0 66px;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 9px;
`;

export const Info = styled.div`
  color: ${path(['theme', 'palette', 'nnBlue'])};
  font-weight: ${path(['theme', 'fontWeight', 'regular'])};
  font-size: 18px;
  line-height: 24px;
  max-width: 521px;
  margin: 0 auto;
`;

export const DropDownWrapper = styled.div`
  width: 291px;
  margin-bottom: 28px;
`;

export const TermsText = styled.div`
  color: ${path(['theme', 'palette', 'jumbo'])};
  font-weight: ${path(['theme', 'fontWeight', 'regular'])};
  font-weight: ${path(['theme', 'fontWeight', 'regular'])};
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
`;

export const TermsLink = styled(Link)`
  text-decoration: underline;
  margin-left: 5px;
`;

export const Element = styled.div<{ stretch?: boolean }>`
  text-align: center;

  ${props => props.stretch ? `
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    align-items: stretch;
  `: ''}
`;
