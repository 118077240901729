import React from 'react';
import { navigate } from 'gatsby';
import {
  useAuthContext,
  useUserContext,
} from '@nn-virtual-pen/education/data-access';
import { RoutePath, useIsInitializing } from '@nn-virtual-pen/education/utils';
import { getIsBrowser } from '@nn-virtual-pen/utils';

export const withPrivate = (WrappedComponent) => (props) => {
  const isBrowser = getIsBrowser();
  const isInitializing = useIsInitializing();

  const {
    actions,
    state: { token },
  } = useAuthContext();
  const user = useUserContext();

  if (isBrowser && !isInitializing && !token) {
    void navigate(RoutePath.Welcome);
    return null;
  }

  return (
    <WrappedComponent
      {...props}
      isLoading={isInitializing}
      actions={actions}
      user={user}
    />
  );
};
