import styled from 'styled-components';
import Svg from './layout.svg';
import { Title as BaseTitle } from '@nn-virtual-pen/education/ui';
import { path } from 'ramda';
import { Link } from '@reach/router';

export const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.palette.white};
  min-height: 100%;
`;

export const Header = styled.div`
  height: 240px;
  width: 100%;
  padding: 28px 21px 0;

  position: relative;
  background: linear-gradient(90deg, #c471ed, #f7797d);

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background: url(${Svg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
  }

  @media ${props => props.theme.media.desktop} {
    background: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  align-items: stretch;
  padding: 0 30px;
`;

export const Footer = styled.div`
  padding: 20px 37px;
  text-align: center;
`;

export const Title = styled(BaseTitle)`
  font-size: 35px;
  line-height: 49px;
  text-align: center;

  @media ${props => props.theme.media.desktop} {
    font-size: 38px;
    line-height: 52px;
  }
`;

export const Text = styled.p<{ primary?: boolean }>`
  font-size: 13px;
  line-height: 18px;
  color: ${(props) =>
    props.primary ? props.theme.palette.nnBlue : props.theme.palette.white};
  font-weight: ${(props) => props.theme.fontWeight.regular};
`;

export const FormElement = styled.div`
  margin-bottom: 48px;
`;

export const TermsText = styled.div`
  color: ${path(['theme', 'palette', 'jumbo'])};
  font-weight: ${path(['theme', 'fontWeight', 'regular'])};
  font-weight: ${path(['theme', 'fontWeight', 'regular'])};
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
`;

export const TermsLink = styled(Link)`
  text-decoration: underline;
`;
