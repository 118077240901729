import React, { FC } from 'react';
import svg from './pen-with-cap.svg';

export const NoPenPlaceholder: FC = () => {
  return (
    <div style={{ margin: '0 auto' }}>
      <img src={svg} alt=""/>
    </div>
  );
};
