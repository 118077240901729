import React from 'react';
import { navigate } from 'gatsby';
import {
  MobileHome,
  DesktopHome,
} from '@nn-virtual-pen/education/features/home-page';
import { GenericLayout } from '../layouts';
import { RoutePath } from '@nn-virtual-pen/education/utils';
import { Helmet } from 'react-helmet';
import { cond, prop, T } from 'ramda';
import { withRequireConfig } from '../hoc';
import { Initialization } from '../components/initialization';
import { useAudioContext, useUserContext } from "@nn-virtual-pen/education/data-access";

export const Home = () => {
  const { trackRef } = useAudioContext();
  const { setUserInteracted } = useUserContext();

  const callback = (): Promise<void> => {
    setUserInteracted(true);
    void trackRef.current.play();

    return navigate(RoutePath.Learning);
  };

  return (
    <GenericLayout withMedia>
      {({ mobile, tablet, desktop }) => (
        <>
          <Helmet title="Home" />
          {(mobile) && (
            <MobileHome callback={callback} />
          )}
          {(tablet || desktop) && (
            <DesktopHome callback={callback} />
          )}
        </>
      )}
    </GenericLayout>
  );
};

export default withRequireConfig(
  cond([
    [prop('isLoading'), Initialization],
    [T, Home],
  ])
);
