import React from 'react';
import {
  BlurredCircle as StyledBlurredCircle,
  BlurredCirclePosition,
  Title,
} from '@nn-virtual-pen/education/ui';
import {
  ActionButton,
  ActionButtonSize,
  ActionButtonVariant,
} from '@nn-virtual-pen/ui';
import * as Styled from './desktop-home.styled';
import { Content } from '../content';
import {
  TranslationKey,
  useTranslate,
} from '@nn-virtual-pen/education/data-access';

export const DesktopHome = ({ callback }) => {
  const { t } = useTranslate();

  return (
    <Styled.Wrapper>
      <StyledBlurredCircle position={BlurredCirclePosition.left} />
      <Styled.Element>
        <Styled.TitleWrapper>
          <Title>{t(TranslationKey.pen_text)}</Title>
        </Styled.TitleWrapper>
      </Styled.Element>
      <Styled.Element stretch>
        <Content />
      </Styled.Element>
      <Styled.Element>
        <ActionButton
          type="button"
          onClick={callback}
          variant={ActionButtonVariant.primary}
          size={ActionButtonSize.wide}
          title={t(TranslationKey.continue_button)}
        >
          {t(TranslationKey.continue_button)}
        </ActionButton>
        <Styled.TermsText>
          {t(TranslationKey.page1_terms_desktop_1)}
          <Styled.TermsLink to={'/terms-of-condition'}>
            {t(TranslationKey.terms_of_condition)}
          </Styled.TermsLink>
        </Styled.TermsText>
      </Styled.Element>
    </Styled.Wrapper>
  );
};
