import React, { useEffect, useState } from 'react';
import { getIsBrowser } from '@nn-virtual-pen/utils';
import { useLocation } from '@reach/router';
import {
  useAuthContext,
  useUserContext,
} from '@nn-virtual-pen/education/data-access';
import { navigate } from 'gatsby';
import { RoutePath, useIsInitializing } from '@nn-virtual-pen/education/utils';

export const withUnauthenticated = (WrappedComponent) => (props) => {
  const isBrowser = getIsBrowser();
  const { pathname, search } = useLocation();
  const isInitializing = useIsInitializing();
  const {
    actions,
    state: { token },
  } = useAuthContext();
  const user = useUserContext();
  const [init, setInit] = useState(false);

  const initialAuthentication = async () => {
    if (search) {
      const [, _token] = search.split('token=');
      await actions.authenticateToken(_token);
    }
    setInit(true);
  };

  useEffect(() => {
    initialAuthentication();
  }, []);

  const {
    configuration: { country, product, device },
  } = user;

  const configurationCompleted = country && product && device;

  useEffect(() => {
    if (isBrowser && !isInitializing && init) {
      if (!token) {
        if (pathname !== '/') {
          void navigate(RoutePath.Welcome);
        }
      } else {
        if (configurationCompleted) {
          void navigate(RoutePath.Home);
        } else {
          void navigate(RoutePath.Configure);
        }
      }
    }
  }, [isInitializing, configurationCompleted, token, init]);

  return (
    <WrappedComponent
      {...props}
      isLoading={isInitializing || !init}
      actions={actions}
      user={user}
    />
  );
};
